import React from 'react';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';

import CmsBlocks from '@sportson/core-web/libs/wordpress/content/CmsBlocks';
import AspectWrapper from '@sportson/core-web/components/AspectWrapper';
import Columns from '@sportson/core-web/App/layouts/Columns';
import Waterfall from '@sportson/core-web/App/layouts/Waterfall';
import FullWidth from '@sportson/core-web/App/layouts/FullWidth';
import { ArrowIcon } from 'assets/media/icons/ArrowIcon';

import { type WordpressCallToActionBlock } from 'libs/wordpress/content/Blocks/CallToAction';
import { type WordpressGenericFlexibleContent } from 'libs/wordpress/types/acf/fields';
import { type WordpressDiscoverBlock } from 'libs/wordpress/content/Blocks/Discover';
import { type WordpressInformationBlock } from 'libs/wordpress/content/Blocks/Information';
import {
    type WordpressFlexibleNavigation,
    type WordpressHeadingGroup,
} from 'libs/wordpress/types/contentSystem/groups';
import { transformLinkButton } from 'libs/wordpress/utils/transformButton';
import { transformHeadingGroup } from 'libs/wordpress/utils/transformHeading';
import { type WordpressContentRatio, type WordpressCustomColumnOrderTwo } from '../../types/contentSystem/settings';
import { type WordpressSpacingGroup } from '../../types/contentSystem/groups';
// import { WordpressVideoInlineBlock } from "libs/wordpress/content/Blocks/VideoInline";
// import { WordpressBackgroundImageBlock } from "libs/wordpress/content/Blocks/Image";

interface WordpressTwoColumnsModuleContentColumnsCallToAction extends WordpressGenericFlexibleContent {
    layout: 'callToAction';
    data: WordpressCallToActionBlock;
}
interface WordpressTwoColumnsModuleContentColumnsDiscover extends WordpressGenericFlexibleContent {
    layout: 'discover';
    data: WordpressDiscoverBlock;
}
interface WordpressTwoColumnsModuleContentColumnsInformation extends WordpressGenericFlexibleContent {
    layout: 'information';
    data: WordpressInformationBlock;
}

// interface WordpressTwoColumnsModuleContentColumnsImage extends WordpressGenericFlexibleContent {
//     layout: "image";
//     data: WordpressBackgroundImageBlock;
// }

// interface WordpressTwoColumnsModuleContentColumnsVideoInline extends WordpressGenericFlexibleContent {
//     layout: "videoInline";
//     data: WordpressVideoInlineBlock;
// }

export type WordpressTwoColumnsModuleColumnContentBlock =
    | WordpressTwoColumnsModuleContentColumnsCallToAction
    | WordpressTwoColumnsModuleContentColumnsInformation
    | WordpressTwoColumnsModuleContentColumnsDiscover;
// | WordpressTwoColumnsModuleContentColumnsImage
// | WordpressTwoColumnsModuleContentColumnsVideoInline;

export interface TwoColumnModuleProps extends Record<string, unknown> {
    data: {
        data: {
            columns: Array<
                WordpressTwoColumnsModuleColumnContentBlock & {
                    customColumnOrder?: WordpressCustomColumnOrderTwo;
                }
            >;
            heading?: WordpressHeadingGroup;
            link?: WordpressFlexibleNavigation;
            settings?: Array<'heading' | 'link'>;
        };
        moduleSettings: {
            reverseColumnsInMobile: 'no' | 'yes' | 'custom';
            spacing: WordpressSpacingGroup;
            contentRatio: WordpressContentRatio;
            desktopContentLayout: '50|50' | '66|33' | '33|66';
            mobileContentLayout: 'columns,50|50' | 'rows,100|100' | 'rows,100|80' | 'rows,80|100';
        };
    };
    isHero?: boolean;
}

export interface UseColumnsProps extends Record<string, unknown> {
    children: React.ReactNode;
    useColumns: boolean;
}

const UseColumns: React.FunctionComponent<UseColumnsProps> = ({ children, useColumns }) =>
    useColumns ? (
        <Columns columns="2" columnGap="16px" height="100%">
            {children}
        </Columns>
    ) : (
        <Waterfall rowGap="12px">{children}</Waterfall>
    );

const ColumnsTwo: React.FunctionComponent<TwoColumnModuleProps> = ({ data, isHero, ...rest }) => {
    const {
        moduleSettings,
        data: { columns, heading, link, settings },
    } = data;

    const { reverseColumnsInMobile } = moduleSettings;

    const isMobile = useBreakpoint({ to: 'desktop.sm' });
    const device = isMobile ? 'mobile' : 'desktop';

    // TODO: Comment back when Heading is implemented in CMS
    // const moduleData = {};
    // // Heading
    // if (settings.includes('heading') && heading) {
    //     moduleData.heading = transformHeadingGroup(heading, device);
    // }

    // TODO: Comment back when Heading is implemented in CMS
    // // Link
    // if (settings.includes('link') && link) {
    //     const linkType = link[0].layout;
    //     if (linkType === 'buttons') {
    //         moduleData.buttons = link[0].buttons.map((button) => {
    //             const tButton = transformLinkButton(button.button);
    //             return {
    //                 url: tButton.link,
    //                 children: (
    //                     <>
    //                         {tButton.label}
    //                         <ArrowIcon />
    //                     </>
    //                 ),
    //                 theme: tButton.theme,
    //             };
    //         });
    //     } else if (linkType === 'content') {
    //         moduleData.contentLink = link[0].link;
    //     }
    // }

    // @TODO Implement background, spacing, ratio and visibility
    return (
        <FullWidth
            gridColumn="full-width"
            mt="var(--module-margin-top-default)"
            mb="var(--module-margin-bottom-default)"
            p={['40px 12px', null, '24px 16px', null, '40px']}
            backgroundColor="var(--color-transparent)"
        >
            <UseColumns useColumns={!isMobile}>
                <CmsBlocks
                    {...rest}
                    data={reverseColumnsInMobile === 'yes' && isMobile ? Object.values(columns).reverse() : columns}
                    columnLayout="columnsTwo"
                    layout="twoColumns"
                />
            </UseColumns>
        </FullWidth>
    );
};

export default ColumnsTwo;
